import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton, SecondaryButton } from '../../components';
import { checkIsOldCar } from '../../util/dates';
import { getTransitButtonLabelId } from './transitionHelper';
import { flatten } from 'lodash';
import {
  isNeedCustomerDropOffAction,
  isNeedCustomerPickUpAction,
  isNeedProviderDropOffAction,
  isNeedProviderPickUpAction,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_DISPUTE,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  txIWaitingConfirmPickup,
} from '../../util/transaction';

import css from './TripPanel.css';
import get from 'lodash/get';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import {
  EVENT_CONVERSION_BOOKING_DECLINED,
  EVENT_CONVERSION_BOOKING_REQUEST_ACCEPTED,
} from '../../util/conversions/conversionsConstants';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { formatMoney } from '../../util/currency';

const API_URL = `${process.env.REACT_APP_API_SERVER_URL}/api/pdf/fill-insurance-form`;

const fillInsuranceForm = ({
  userId,
  listingId,
  guestName,
  pickUpDate,
  dropOffDate,
  plateNumber,
  chasisNumber,
  isOldCar,
  transactionId,
}) => {
  return fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      listingId,
      guestName,
      pickUpDate,
      dropOffDate,
      plateNumber,
      chasisNumber,
      isOldCar,
      transactionId,
    }),
  });
};

const ButtonsSection = ({
  currentOdometerReading,
  dlGoTripDistanceInProgress,
  fuelPrice,
  declineInProgress,
  declineButtonName,
  transaction,
  acceptInProgress,
  acceptSaleError,
  declineSaleError,
  acceptButtonName,
  currentUser,
  onDeclineSale,
  declineSaleGTM,
  onAcceptSale,
  acceptSaleGTM,
  currentPhotos,
  activePhotoSection,
  exteriorPhotos,
  selfieWithId,
  interiorPhotos,
  odometerPhotos,
  fuelGaugePhotos,
  fuelReceiptPhotos,
  pickupProviderPhoto,
  otherPhotos,
  otherProviderPhotos,
  transitionPickUpMaybe,
  transitionMaybe,
  onTransit,
  showSaleButtons,
  isShuEnabledCar,
  showPickUpSection,
  transitInProgress,
  allowUserTransit,
  emptyPhoto,
  inputOdometerInvalid,
  isCustomer,
  isProvider,
  allTripPhotos,
  showDropOffSection,
  isPickUp,
  isDropOff,
  isDrivelahGo,
  onPushEventGTMPickUpDropOff,
  ownListing,
  onUploadInteriorPhotoToMetadata,
  interiorPhotoDlGo,
  uploadInteriorPhotoProgress,
  uploadInteriorSuccess,
  // onHandlePaymentFuel,
  listing,
  isFuelIncluded,
  onEstimateFuelBreakdown,
  odometerStartDataStateUp,
  odometerEndDataState,
  isFuelChildTransaction,
  // uploadInteriorSuccess,
  shouldShowPickUpSection,
  shouldShowDropOffSection,
  isOdometerStartDataValid,
  isOdometerEndDataValid,
  estimateBreakdownInProgress,
  initiateOrderInProgress,
  handleCardPaymentInProgress,
  confirmPaymentInProgress,
  savePaymentMethodInProgress,
  payForFuelSubmit,
  intl,
  dropOffMarker,
  setDropOffMarker,
  fuelNotificationWithoutCharge,
  getDlGoDistance,
  onUpdateListingOdometerData,
  onFuelNotificationFor500,
  onPushDisputeEventGTM,
}) => {
  let fuelSpeculativeTransaction = null;
  const buttonsDisabled = acceptInProgress || declineInProgress;
  const customerPickUpAction = showPickUpSection && isCustomer && isNeedCustomerPickUpAction(transaction);
  const customerDropOffAction = showDropOffSection && isCustomer && isNeedCustomerDropOffAction(transaction);
  const providerPickUpAction = showPickUpSection && isProvider && isNeedProviderPickUpAction(transaction);
  const providerDropOffAction = showDropOffSection && isProvider && isNeedProviderDropOffAction(transaction);
  const customerPickUpInteriorAction = showPickUpSection && isCustomer && isDrivelahGo && isNeedCustomerDropOffAction(transaction);
  const photoObjects = isDrivelahGo && transaction &&
    transaction.attributes &&
    transaction.attributes &&
    transaction.attributes.metadata &&
    transaction.attributes.metadata.photoObjects ?
    transaction.attributes.metadata.photoObjects :
    transaction.attributes.protectedData.photoObjects;
  const photoObjectsFlat = photoObjects ? flatten(photoObjects) : [];
  const odometerPhotosObject = photoObjectsFlat ? photoObjectsFlat.find((photo) => photo.odometerPhotos) : [];
  let odometerStartDataState = odometerPhotosObject && parseInt(odometerPhotosObject.odometerStartData);
  if (!odometerStartDataState) {
    odometerStartDataState = odometerStartDataStateUp;
  }
  const isFuelInclusion = get(transaction, 'attributes.protectedData.isFuelInclusion', null)
    || get(transaction, 'attributes.metadata.isFuelInclusion', null)
    ;
  const validTransaction = transaction && transaction.customer;
  // Removed the unverified check as admin can accept the booking
  const acceptAllow = validTransaction

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;
  const guestIdentityIsNotVerify = !acceptAllow ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.guestIdentityIsNotVerify" />
    </p>
  ) : null;

  const numberActivePhotoSection =
    activePhotoSection && activePhotoSection.filter(i => i === true).length;
  const isActivePhotoSection = isCustomer ? numberActivePhotoSection > 0 : true;
  const allCurrentTripPhotos = [...currentPhotos, ...allTripPhotos];
  const currentExteriorPhotos = exteriorPhotos;
  const currentInteriorPhotos = interiorPhotos;
  const currentOdometerPhotos = odometerPhotos;
  const currentFuelGaugePhotos = fuelGaugePhotos;
  const currentSelfieWithId = selfieWithId;
  const currentOtherPhotos = allCurrentTripPhotos.filter(p => p.type === 'otherPhotos');
  const dlGoExteriorPhotos = flatten(allTripPhotos).filter(i => 'exteriorPhotos' in i);
  const dlGoSelfieWithIdPhotos = flatten(allTripPhotos).filter(i => 'selfieWithId' in i);

  let validPhotoSection = false;
  if (
    !isDrivelahGo
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 4)
    && (currentInteriorPhotos && currentInteriorPhotos.length >= 1)
    && (currentOdometerPhotos && currentOdometerPhotos.length >= 1)
    && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1)
    && ((isPickUp && isOdometerStartDataValid) || (isDropOff && isOdometerEndDataValid))
  ) {
    validPhotoSection = true;
  }
  if (isDrivelahGo && isPickUp
    && (currentExteriorPhotos && currentExteriorPhotos.length >= 4)
    && (currentSelfieWithId && currentSelfieWithId.length >= 1)
    && (currentInteriorPhotos && currentInteriorPhotos.length >= 1)
    && (currentOdometerPhotos && currentOdometerPhotos.length >= 1)
    && (currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1)
  ) {
    validPhotoSection = true;
  }
  if (
    isDrivelahGo && isDropOff &&
    currentExteriorPhotos && currentExteriorPhotos.length >= 4 &&
    currentInteriorPhotos && currentInteriorPhotos.length >= 1 &&
    currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1
  ) {
    validPhotoSection = true;
  }

  if (
    isDrivelahGo && showPickUpSection && isDropOff
    && (dlGoExteriorPhotos && dlGoExteriorPhotos.length >= 4)
    && (dlGoSelfieWithIdPhotos && dlGoSelfieWithIdPhotos.length >= 1)
    && (currentInteriorPhotos && currentInteriorPhotos.length >= 1 || currentFuelGaugePhotos && currentFuelGaugePhotos.length >= 1)
  ) {
    validPhotoSection = true;
  }

  let validFuelPayment = true;
  if (isPickUp && isCustomer && isFuelIncluded && !isDrivelahGo && currentOdometerReading) {
    if (!odometerStartDataState || Number(odometerStartDataState) < Number(currentOdometerReading)) {
      validFuelPayment = false;
    }
  }
  if (
    isDropOff && isCustomer && isFuelIncluded && !isDrivelahGo
    && (odometerEndDataState === null || Number(odometerEndDataState) <= odometerStartDataState)
  ) {
    validFuelPayment = false;
  }
  if (isDropOff && isCustomer && isFuelIncluded && isDrivelahGo) {
    validFuelPayment = true;
  }

  const isHost = currentUser.id.uuid === transaction.provider.id.uuid;

  const isEligbleForStartCar =
    isShuEnabledCar &&
    transitionMaybe === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE;
  const isStartCar = isHost && isEligbleForStartCar;

  const isEligibleForPushConfig =
    isShuEnabledCar &&
    (transitionMaybe === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE ||
      transitionMaybe === TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED);
  const isShuCar = !isHost && isEligibleForPushConfig;

  const eligibalTransitionForStopCar = (transitionMaybe) => {
    if (Array.isArray(transitionMaybe)) {
      return transitionMaybe.includes(TRANSITION_CONFIRM_DROP_OFF) ||
        transitionMaybe.includes(TRANSITION_DISPUTE)
    } else {
      return [TRANSITION_CONFIRM_DROP_OFF, TRANSITION_DISPUTE].includes(transitionMaybe)
    }
  }
  const isStopCar = (eligibalTransitionForStopCar(transitionMaybe) && isShuEnabledCar && isHost);

  const updateCurrentOdometerReading = () => {
    if (isCustomer && isFuelInclusion) {
      if (isPickUp) {
        const odometerStartValue = get(odometerPhotos, '[0].odometerStartData', null);
        if (odometerStartValue) {
          onUpdateListingOdometerData(listing.id.uuid, odometerStartValue);
        }
      } else if (isDropOff) {
        const odometerEndValue = get(odometerPhotos, '[0].odometerEndData', null);
        if (odometerEndValue) {
          onUpdateListingOdometerData(listing.id.uuid, odometerEndValue);
        }
      }
    }
  };

  const sendFuelNotificationFor500 = fuelObj => {
    if (Object.keys(fuelObj).length) {
      const bookingStart = get(transaction, 'booking.attributes.displayStart', null);
      const bookingEnd = get(transaction, 'booking.attributes.displayEnd', null);
      const tripDays = moment(bookingEnd).diff(moment(bookingStart), 'days');
      if ((fuelObj.distanceTravelled / Math.ceil(tripDays)) > 500) {
        onFuelNotificationFor500(transaction.id.uuid);
      }
    }
  };

  const handlePrimaryButtonClick = (transaction, ownListing, selectedTransition, dlGoTripDistance = null) => {
    // selectedTransition is used when multiple states are available.
    const singleTransition = (selectedTransition || transitionMaybe);
    let fuelObj = {};
    if (fuelSpeculativeTransaction) {
      if (odometerEndDataState && odometerStartDataState) {
        fuelObj = {
          distanceTravelled: odometerEndDataState - odometerStartDataState,
          fuelCharge: {
            totalDistance: odometerEndDataState - odometerStartDataState,
            payoutTotal: formatMoney(intl, fuelSpeculativeTransaction.attributes.payoutTotal, 1),
            payinTotal: formatMoney(intl, fuelSpeculativeTransaction.attributes.payinTotal, 1),
          },
        };
      }
      if (dlGoTripDistance) {
        fuelObj = {
          distanceTravelled: dlGoTripDistance,
          fuelCharge: {
            totalDistance: dlGoTripDistance,
            payoutTotal: formatMoney(intl, fuelSpeculativeTransaction.attributes.payoutTotal, 1),
            payinTotal: formatMoney(intl, fuelSpeculativeTransaction.attributes.payinTotal, 1),
          },
        };
      }
    }
    const dataObject = {
      ...providerDropOffAction && { providerDropOffAction: true },
      ...fuelObj,
    };
    onPushEventGTMPickUpDropOff(transaction, singleTransition);

    const sections = isProvider
      ? ['providerPickup', 'providerOther']
      : isDrivelahGo && isPickUp
        ? ['exterior', 'selfieWithId', 'interior', 'odometer', 'fuel', 'fuelReceipt', 'other']
        : ['exterior', 'interior', 'odometer', 'fuel', 'fuelReceipt', 'other'];
    let currentPhotos = [];
    activePhotoSection.map((section, index) => {
      if (section === true) {
        if (sections[index] === 'exterior') {
          currentPhotos = [...currentPhotos, exteriorPhotos];
        }
        if (sections[index] === 'selfieWithId') {
          currentPhotos = [...currentPhotos, selfieWithId];
        }
        if (sections[index] === 'interior') {
          currentPhotos = [...currentPhotos, interiorPhotos];
        }
        if (sections[index] === 'odometer') {
          currentPhotos = [...currentPhotos, odometerPhotos];
        }
        if (sections[index] === 'fuel') {
          currentPhotos = [...currentPhotos, fuelGaugePhotos];
        }
        if (sections[index] === 'fuelReceipt') {
          currentPhotos = [...currentPhotos, fuelReceiptPhotos];
        }
        if (sections[index] === 'other') {
          currentPhotos = [...currentPhotos, otherPhotos];
        }
        if (sections[index] === 'providerPickup') {
          currentPhotos = [...currentPhotos, ...allTripPhotos, pickupProviderPhoto];
        }
        if (sections[index] === 'providerOther') {
          currentPhotos = [...currentPhotos, ...allTripPhotos, otherProviderPhotos];
        }
      }
    });
    const allPhotos = currentPhotos.length > 0 ? currentPhotos : allTripPhotos;
    const allPhotosDropOff = isDropOff
      ? isCustomer
        ? [...allTripPhotos, ...allPhotos]
        : allPhotos
      : allPhotos;

    if (
      txIWaitingConfirmPickup(transaction) &&
      (singleTransition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE) &&
      ownListing &&
      ownListing.attributes.publicData.insurance === 'private'
    ) {
      const { provider, customer, booking } = transaction;
      fillInsuranceForm({
        userId: provider.id.uuid,
        listingId: ownListing.id.uuid,
        guestName: customer.attributes.profile.displayName,
        pickUpDate: moment(booking.attributes.start).format('DD/MM/YYYY'),
        dropOffDate: moment(booking.attributes.end).format('DD/MM/YYYY'),
        plateNumber: ownListing.attributes.publicData.license_plate_number,
        chasisNumber: ownListing.attributes.privateData.chasisNumber,
        isOldCar: checkIsOldCar(ownListing),
        transactionId: transaction.id.uuid,
      })
        .then(res => res.json())
        .then(data => {
          onTransit(
            transaction.id,
            singleTransition,
            dataObject,
            [...allPhotosDropOff],
            data,
            isShuCar,
            isStartCar,
            null,
            null,
            isStopCar
          ).then(() => {
            updateCurrentOdometerReading();
            sendFuelNotificationFor500(fuelObj);
            shouldShowPickUpSection(false);
            shouldShowDropOffSection(false);
            setDropOffMarker(false);
          });
        });
    } else {
      onTransit(
        transaction.id,
        singleTransition,
        dataObject,
        [...allPhotosDropOff],
        null,
        isShuCar,
        isStartCar,
        null,
        null,
        isStopCar
      ).then(() => {
        updateCurrentOdometerReading();
        sendFuelNotificationFor500(fuelObj);
        shouldShowPickUpSection(false);
        shouldShowDropOffSection(false);
        setDropOffMarker(false);
      });
    }
  };

  const dropOffForFuelInclusion = (params, transaction, ownListing, selectedTransition) => {
    onEstimateFuelBreakdown(params)
      .then((estimatedTx) => {
        fuelSpeculativeTransaction = estimatedTx;
        return payForFuelSubmit(estimatedTx);
      })
      .then(() => handlePrimaryButtonClick(transaction, ownListing, selectedTransition, params.dlGoTripDistance))
      .catch(() => {
        fuelNotificationWithoutCharge(transaction.id.uuid);
        handlePrimaryButtonClick(transaction, ownListing, selectedTransition);
      })
      ;
  };

  const handlePayForFuel = (transaction, ownListing, selectedTransition) => {
    if (
      isFuelInclusion
      && isCustomer
      && isDropOff
    ) {
      const listingId = get(transaction, 'listing.id');
      const params = {
        listingId,
        fuelPrice,
      }
      if (!isDrivelahGo) {
        params.odometerStartDataState = odometerStartDataState;
        params.odometerEndDataState = parseInt(odometerEndDataState);

        dropOffForFuelInclusion(params, transaction, ownListing, selectedTransition);
      } else {
        getDlGoDistance(transaction.id.uuid)
          .then(response => {
            params.dlGoTripDistance = response.data.distanceTravelled || 0;
            dropOffForFuelInclusion(params, transaction, ownListing, selectedTransition);
          })
          ;
      }
    } else {
      return handlePrimaryButtonClick(transaction, ownListing, selectedTransition);
    }
  };

  const dropOffInProgress = !!transitInProgress || !!estimateBreakdownInProgress
    || dlGoTripDistanceInProgress
    || !!initiateOrderInProgress || !!handleCardPaymentInProgress
    || !!confirmPaymentInProgress || !!savePaymentMethodInProgress
    ;

  return (
    <div className={css.buttonSectionContainer}>
      <div className={css.buttonSectionInner}>
        {isProvider && showSaleButtons && (
          <div className={css.buttonSectionText}>
            <span>
              Declining too many bookings can have a negative impact on your positioning on search.
            </span>
          </div>
        )}
        {isProvider && showSaleButtons && (
          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              className={css.buttonDisabled}
              inProgress={declineInProgress}
              disabled={buttonsDisabled}
              id={declineButtonName}
              name={declineButtonName}
              onClick={() => {
                onDeclineSale(transaction.id);
                const properties = createRawPropertiesForGTM({ props: currentUser });
                createConversionEvents(properties, EVENT_CONVERSION_BOOKING_DECLINED, 'click');
                declineSaleGTM();
              }}
            >
              <FormattedMessage id="TransactionPanel.declineButton" />
            </PrimaryButton>
            <SecondaryButton
              inProgress={acceptInProgress}
              disabled={buttonsDisabled || !acceptAllow}
              className={css.buttonAccepted}
              id={acceptButtonName}
              name={acceptButtonName}
              onClick={() => {
                if (acceptAllow) {
                  onAcceptSale(transaction.id, currentUser);
                  const properties = createRawPropertiesForGTM({ props: currentUser });
                  createConversionEvents(properties, EVENT_CONVERSION_BOOKING_REQUEST_ACCEPTED, 'click');
                  acceptSaleGTM();
                }
              }}
            >
              <FormattedMessage id="TransactionPanel.acceptButton" />
            </SecondaryButton>
          </div>
        )}

        {customerPickUpInteriorAction && !(interiorPhotoDlGo.length > 0) && !uploadInteriorSuccess && (
          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              className={css.buttonAccepted}
              inProgress={uploadInteriorPhotoProgress || dlGoTripDistanceInProgress}
              disabled={transitInProgress || dlGoTripDistanceInProgress || !validPhotoSection || uploadInteriorPhotoProgress}
              id={declineButtonName}
              name={declineButtonName}
              onClick={() => {
                const sections = isProvider
                  ? ['providerPickup', 'providerOther']
                  : ['exterior', 'selfieWithId', 'interior', 'odometer', 'fuel', 'fuelReceipt', 'other'];
                let currentPhotos = [];
                activePhotoSection.map((section, index) => {
                  if (section === true) {
                    if (sections[index] === 'interior') {
                      currentPhotos = [...currentPhotos, interiorPhotos];
                    }
                    if (sections[index] === 'odometer') {
                      currentPhotos = [...currentPhotos, odometerPhotos];
                    }
                    if (sections[index] === 'fuel') {
                      currentPhotos = [...currentPhotos, fuelGaugePhotos];
                    }
                    if (sections[index] === 'fuelReceipt') {
                      currentPhotos = [...currentPhotos, fuelReceiptPhotos];
                    }
                    if (sections[index] === 'other') {
                      currentPhotos = [...currentPhotos, fuelGaugePhotos];
                    }
                  }
                });
                onUploadInteriorPhotoToMetadata(transaction.id, [...currentPhotos]);
                shouldShowPickUpSection(false);
                shouldShowDropOffSection(false);
              }}
            >
              <FormattedMessage id="TransactionPanel.sendInteriorPhotos" />
            </PrimaryButton>
          </div>
        )}

        {(customerPickUpAction || customerDropOffAction) && (
          <div className={css.buttonSectionWrapper}>
            <PrimaryButton
              id={'submitButtonId'}
              className={css.buttonAccepted}
              type="submit"
              inProgress={dropOffInProgress}
              disabled={dropOffInProgress || !validPhotoSection || !validFuelPayment}
              onClick={() => {
                handlePayForFuel(transaction, ownListing);
              }}
            >
              <FormattedMessage id={getTransitButtonLabelId(transitionMaybe)} />
            </PrimaryButton>
          </div>
        )}

        {(providerPickUpAction || providerDropOffAction) && (
          <div className={css.buttonSectionWrapper}>
            {Array.isArray(transitionMaybe) ? (
              transitionMaybe.map(transitionState => {
                return (
                  <PrimaryButton
                    id={'submitButtonId'}
                    className={css.buttonAccepted}
                    type="submit"
                    inProgress={transitInProgress || dlGoTripDistanceInProgress}
                    disabled={transitInProgress || dlGoTripDistanceInProgress || !isActivePhotoSection || !validFuelPayment}
                    onClick={() => {
                      if (transitionState === "transition/dispute") {
                        onPushDisputeEventGTM();
                      }
                      handlePayForFuel(transaction, ownListing, transitionState);
                    }}
                  >
                    <FormattedMessage id={getTransitButtonLabelId(transitionState)} />
                  </PrimaryButton>
                );
              })
            ) : (
              <PrimaryButton
                id={'submitButtonId'}
                className={css.buttonAccepted}
                type="submit"
                inProgress={transitInProgress || dlGoTripDistanceInProgress}
                disabled={transitInProgress || dlGoTripDistanceInProgress || !isActivePhotoSection || !validFuelPayment}
                onClick={() => {
                  handlePayForFuel(transaction, ownListing);
                }}
              >
                <FormattedMessage id={getTransitButtonLabelId(transitionMaybe)} />
              </PrimaryButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ButtonsSection;

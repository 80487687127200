import moengage from "@moengage/web-sdk";

const MOE_APP_ID = process.env.REACT_APP_MOENGAGE_APP_ID;
const MOE_CLUSTER_ID = process.env.REACT_APP_MOENGAGE_CLUSTER_ID;

export const sendEventToMoEngage = (e, id) => {
    try {
        console.log('I enter in try block of sendEventToMoEngage >>>');
        moengage.initialize({
            app_id: MOE_APP_ID,
            debug_logs: 1,
            enableSPA: true,
            cluster: MOE_CLUSTER_ID
        });
        if (id) {
            moengage.add_unique_user_id(id).then(()=>{
                moengage.track_page_view();
                moengage.track_event(e.event, e.properties);
            })
        }
        else {
            moengage.track_page_view();
            moengage.track_event(e.event, e.properties);
        }
    } catch (err) {
        console.log('I enter in catch block of sendEventToMoEngage >>>');
        console.log('I am err >>>', err);
    }
}
import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  Footer,
  InsuranceDisclaimer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import HIWHeroSection from './HIWHero';
import HIWHowDoesItWorkSection from './HIWHowDoesItWork';
import HIWInsuranceSection from './HIWInsurance';
import HIWReadyToGoSection from './ReadyToGo';
import HIWListSection from './HIWList';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { initiateListACarEventFromTab } from '../../util/gtm/gtmHelpers';
import {
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  LISTACAR_CREATE_NEW_BUTTON_ID,
  LISTACAR_CREATE_NEW_BUTTON_ID_2,
} from '../../util/gtm/gtmConstants';
import css from './HowItWorkPage.css';
import { loginOrSignupClick } from '../TopbarContainer/Topbar.duck';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';

const HowItWorkPage = props => {
  const {
    isAuthenticated,
    onManageDisableScrolling,
    onLoginOrSignupClick,
    currentUser
  } = props;
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);
  const onListYourCar = async (buttonId) => {
    // initiateListACarEventFromTab({
    //   props,
    //   eventButtonParam:
    //     [EVENT_LISTACAR_CLICKED_LIST_A_CAR, buttonId],
    // });
    try {
      const response = await getEventsByBucket('listacar');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.LISTACAR_CLICKED_LIST_A_CAR, 
        eventData: response.data, 
        props: {
          ui: {
            button: "Create Listing"
          }
        },
        userId: currentUser && currentUser.id && currentUser.id.uuid
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
  }
  // prettier-ignore
  return (
    <StaticPage
      title="Car sharing in Singapore made easy with Drive lah"
      description="Learn how Drive Lah works and how you can rent a car easily in Singapore. Discover the benefits and start your journey with us today!"
      schema={[
        {
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: 'Explore the largest marketplace for car sharing in Singapore. With Drive lah, you can rent a car fit for your need anytime anywhere in a few clicks',
          name: 'Car sharing in Singapore made easy with Drive lah',
        },
        {
          '@context': 'https://schema.org',
          '@type': ['VideoObject'],
          'name': 'How does car sharing on Drive lah work?',
          'description': 'Drive lah makes it simple and secure to rent and share cars from real people.',
          'contentUrl': 'https://www.youtube.com/embed/OaOzjZiVaws',
          'thumbnailUrl': [
            'https://www.drivelah.sg/static/media/videoLogo.6f135bad.png',
          ],
          "uploadDate": "2019-05-24T08:00:00+08:00",
        },
      ]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <HIWHeroSection
            isAuthenticated={isAuthenticated}
            onLoginOrSignupClick={onLoginOrSignupClick}
          />
          <HIWHowDoesItWorkSection onManageDisableScrolling={onManageDisableScrolling} />
          <HIWListSection
            onListYourCar={onListYourCar}
            listYourCarButtonId={LISTACAR_CREATE_NEW_BUTTON_ID}
          />
          <HIWInsuranceSection />
          <HIWReadyToGoSection
            isAuthenticated={isAuthenticated}
            onListYourCar={onListYourCar}
            listYourCarButtonId={LISTACAR_CREATE_NEW_BUTTON_ID_2}
            onLoginOrSignupClick={onLoginOrSignupClick}
            />
          <InsuranceDisclaimer/>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser
  };
}

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disabled) => dispatch(manageDisableScrolling(componentId, disabled)),
  onLoginOrSignupClick: (page) => dispatch(loginOrSignupClick({page}))
});

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorkPage);
